module.exports = function (gantt) {
  function isEnabled() {
    return gantt.config.auto_types && // if enabled
    gantt.getTaskType(gantt.config.types.project) == gantt.config.types.project; // and supported
  }

  function callIfEnabled(callback) {
    return function () {
      if (!isEnabled()) {
        return true;
      }

      return callback.apply(this, arguments);
    };
  }

  function updateParents(childId) {
    gantt.batchUpdate(function () {
      checkParent(childId);
    });
  }

  var delTaskParent;

  function checkParent(id) {
    setTaskType(id);
    var parent = gantt.getParent(id);

    if (parent != gantt.config.root_id) {
      checkParent(parent);
    }
  }

  function setTaskType(id) {
    id = id.id || id;
    var task = gantt.getTask(id);
    var targetType = getTaskTypeToUpdate(task);

    if (targetType !== false) {
      updateTaskType(task, targetType);
    }
  }

  function updateTaskType(task, targetType) {
    if (!gantt.getState().group_mode) {
      task.type = targetType;
      gantt.updateTask(task.id);
    }
  }

  function getTaskTypeToUpdate(task) {
    var allTypes = gantt.config.types;
    var hasChildren = gantt.hasChild(task.id);
    var taskType = gantt.getTaskType(task.type);

    if (hasChildren && taskType === allTypes.task) {
      return allTypes.project;
    }

    if (!hasChildren && taskType === allTypes.project) {
      return allTypes.task;
    }

    return false;
  }

  var isParsingDone = true;
  gantt.attachEvent("onParse", callIfEnabled(function () {
    isParsingDone = false;
    gantt.batchUpdate(function () {
      gantt.eachTask(function (task) {
        var targetType = getTaskTypeToUpdate(task);

        if (targetType !== false) {
          updateTaskType(task, targetType);
        }
      });
    });
    isParsingDone = true;
  }));
  gantt.attachEvent("onAfterTaskAdd", callIfEnabled(function (id) {
    if (isParsingDone) {
      updateParents(id);
    }
  }));
  gantt.attachEvent("onAfterTaskUpdate", callIfEnabled(function (id) {
    if (isParsingDone) {
      updateParents(id);
    }
  }));

  function updateAfterRemoveChild(id) {
    if (id != gantt.config.root_id && gantt.isTaskExists(id)) {
      updateParents(id);
    }
  }

  gantt.attachEvent("onBeforeTaskDelete", callIfEnabled(function (id, task) {
    delTaskParent = gantt.getParent(id);
    return true;
  }));
  gantt.attachEvent("onAfterTaskDelete", callIfEnabled(function (id, task) {
    updateAfterRemoveChild(delTaskParent);
  }));
  var originalRowDndParent;
  gantt.attachEvent("onRowDragStart", callIfEnabled(function (id, target, e) {
    originalRowDndParent = gantt.getParent(id);
    return true;
  }));
  gantt.attachEvent("onRowDragEnd", callIfEnabled(function (id, target) {
    updateAfterRemoveChild(originalRowDndParent);
    updateParents(id);
  }));
  var originalMoveTaskParent;
  gantt.attachEvent("onBeforeTaskMove", callIfEnabled(function (sid, parent, tindex) {
    originalMoveTaskParent = gantt.getParent(sid);
    return true;
  }));
  gantt.attachEvent("onAfterTaskMove", callIfEnabled(function (id, parent, tindex) {
    if (document.querySelector(".gantt_drag_marker")) {
      // vertical dnd in progress
      return;
    }

    updateAfterRemoveChild(originalMoveTaskParent);
    updateParents(id);
  }));
};